import React, { useEffect, useState } from "react";
import Menu from "./components/Navbar";
import { Tilt } from 'react-tilt';
import About from "./components/About";
import Feature from "./components/Feature";
import ScreenShot from "./components/Screenshot";
import Blog from "./components/Blog";
import Contact from "./components/Contact";
import Subscribe from "./components/Subscribe";
import Footer from "./components/Footer";
import axios from "axios";

const HomeOne = () => {
  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);

  /*For changing background image by changing the color*/
  const [bgImg, setBgImg]= useState({
    backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/1.png)`
  });
  const color= localStorage.getItem("color");

  const [getAbout, setAbout] = useState([]);

  const _getAbout = async () => {

    await axios.get(`https://kuponifypremium.com.tr:8099/getAboutKupon/select/`)
      .then((res) => {
        setAbout(res.data)
      })
  }
    
  useEffect(() => {
    _getAbout()
   
  }, [getAbout]);

  useEffect(()=>{
    if(color === "color-1")
     setBgImg( {
      backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/1.png)`
    });
    else if(color === "color-2")
    setBgImg({ backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/color/color-2/1.png)`
	})
    else 
    setBgImg({backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/color/color-3/1.png)`
	})
  },[color]);
  
  document.body.classList.remove("landing-page");
  document.body.classList.remove("home-style");
  document.body.classList.remove("three");
  document.body.classList.remove("home-style-two");
  return (
    <div>
      {/* Navbar Component*/}
      <Menu homePage="home-one"/>
      {/* Home One Section Start */}
      <section id="home" className="home" style={bgImg}>
        <div className="home-decor">
          <div className="home-circle1">
            <img src={`${process.env.PUBLIC_URL}/assets/images/main-banner3.png`} alt="" />
          </div>
          <div className="home-circle2">
            <img src={`${process.env.PUBLIC_URL}/assets/images/main-banner12.png`} alt="" />
          </div>
          <div className="home-circle3">
            <img src={`${process.env.PUBLIC_URL}/assets/images/main-banner1.png`} alt="" />
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="home-contain">
                <div>

               

                  <img src={`${process.env.PUBLIC_URL}/assets/images/logoimage.png`} alt="caption-img" className="caption-img" />
                
                  <h1>
                  KUPONIFY<span className="f-bold"></span>
                    <span className="f-bold ">PREMIUM</span>
                  </h1>

                 { getAbout.length > 0 && getAbout[0].SliderDesc ?
                  <p>
                 {getAbout[0].SliderDesc}
                  </p> : null}
                  <a target="_blank" href="https://apps.apple.com/tr/app/kupon-f%C4%B1rsati/id6476834988?l=tr" onClick={(e)=>{e.preventDefault()}}>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/app-store.png`} alt="appstore" className="store" />
                  </a>
                  <a target="_blank" href="https://play.google.com/store/apps/details?id=com.kuponankaramobile" onClick={(e)=>{e.preventDefault()}}>
                    <img
                      className="ml-10 store"
                      src={`${process.env.PUBLIC_URL}/assets/images/playstore.png`}
                      alt="play-store"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6 offset-md-1">
              <div className="home-right">
                <Tilt
                  options={{
                    perspective: 110,
                    speed: 400,
                    max: 1.2,
                    scale: 1,
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/assets/images/mobile.png`} className="img-fluid" alt="mobile" />
                </Tilt>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Home One Section End */}
      {/* About Component*/}
      <About />
      {/*Feature Component*/}
      <Feature />
      {/*ScreenShot Component*/}
      <ScreenShot />
      {/*Team   <Team />Component*/}
    
      {/*Blog Component*/}
      <Blog />
      {/*Price <Price />Component*/}
      
      {/*Testimonial  <Testimonial />Component*/}
     
      {/*Contact Component*/}
      <Contact />
      {/*Subscription Component*/}
      <Subscribe />
      {/*Footer Component*/}
      <Footer />
     
    </div>
  );
};

export default HomeOne;
