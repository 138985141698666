import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const Subscribe = () => {

  const [email, setEmail] = useState(null);


  const handleSubmit = async(e) => {
    e.preventDefault();
    var localDate = moment().local();    

    const newOrder = {
      SubsUser_MailAddress: email,
      Created_DateTime: localDate,
     
    };
    // save new order
  
    await axios.post(`https://kuponifypremium.com.tr:8099/addSubscriptionUser/send`, newOrder).then(async(res) => {
      console.log("mdflş",res)
      showToast(true)
      setEmail(null)
    })
      
    
   
  };

  function showToast(type) {

    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Başarıyla Abonelik İşleminiz Gerçekleşmiştir!"
    } else{
       ele = "error"
       message = "Abonelik olurken bir hata oluştu!"
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }

  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);
  return (
<section>
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="footer-text">
              <img src={`${process.env.PUBLIC_URL}/assets/images/email.png`} alt="email" />
              <h2 className="title text-center md-margin-top">
                KuponifyPremium'a <span>Abone Ol</span>
              </h2>
              <p>
                Yeni kampanyalar hakkında haberdar ol
              </p>
              <form onSubmit={handleSubmit} className="footer-form needs-validation">
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Mail Adresini Giriniz"
                    name="EMAIL"
                    id="mce-EMAIL"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    style={{ textTransform: "none" }} // Inline CSS

                  />
                </div>
                <div className="form-button">
                  <button type="submit" className="btn btn-custom theme-color">
                    Gönder
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
