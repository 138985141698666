import axios from "axios";
import React, { useEffect, useState } from "react";

const Footer = () => {

  
  const [getAbout, setAbout] = useState([]);

  const _getAbout = async () => {

    await axios.get(`https://kuponifypremium.com.tr:8099/getAboutKupon/select/`)
      .then((res) => {
        setAbout(res.data)
      })
  }
    
  useEffect(() => {
    _getAbout()
   
  }, [getAbout]);

  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);
  return (
    <div>
      <section className="p-0">
        <div className="container-fluid">
          <div className="bottom-section">
            <div className="row">
              <div className="col-md-6 p-0">
                <div className="address-bar">
                  <div>
                    <ul className="footer-style">
                      <li>
                        <div className="footer-icon">
                          <img src={`${process.env.PUBLIC_URL}/assets/images/locate.png`} alt="locate" />
                        </div>
                       { getAbout.length > 0 ? <div className="footer-address">
                          <a href="javascript" onClick={(e)=>{e.preventDefault()}}>
                         {getAbout[0].AboutAdress}
                          </a>
                        </div> : null}
                      </li>
                      <li>
                        <div className="footer-icon">
                          <img src={`${process.env.PUBLIC_URL}/assets/images/telephone.png`} alt="telephone" />
                        </div>
                        { getAbout.length > 0 ?   <div className="footer-address">
                          <a href="javascript" onClick={(e)=>{e.preventDefault()}}>{getAbout[0].About_Number}</a>
                        </div> : null }
                      </li>
                      <li>
                        <div className="footer-icon">
                          <img src={`${process.env.PUBLIC_URL}/assets/images/fotter-email.png`} alt="fotter-email" />
                        </div>
                        { getAbout.length > 0 ?    <div className="footer-address">
                          <a href="javascript" onClick={(e)=>{e.preventDefault()}}>{getAbout[0].About_Mail}</a>
                        </div>: null }
                      </li>
                      <li>
                       
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 p-0">

                <iframe
                  title="google-map"
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3060.346521067848!2d32.81015897600216!3d39.911260886248805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMznCsDU0JzQwLjUiTiAzMsKwNDgnNDUuOCJF!5e0!3m2!1str!2str!4v1727851489763!5m2!1str!2str"                  allowFullScreen
                  className="md-height"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

    {/**   <div className="copyright-section index-footer">
        <p>2024 copyright by Stechome</p>
      </div>*/}

      <div className="tap-top">
        <div>
          <i className="fa fa-angle-double-up"></i>
        </div>
      </div>
      <div id="fb-root"></div>
      {/*Your customer chat code*/}
      <div
        className="fb-customerchat"
        page_id="2123438804574660"
        theme_color="#18e7d3"
        logged_in_greeting="Merhaba! Size Nasıl Yardımcı Olabilirim?"
        logged_out_greeting="Merhaba! Size Nasıl Yardımcı Olabilirim?"
      ></div>
    </div>
  );
};

export default Footer;
